<!--
File: DepsDropdown.vue
Description: show the dropdown combo with the DEU list .
-->
<template>
  <md-field>
    <label for="deu_id">{{ label }}</label>
    <md-select v-model='deu_id' name="deu_id" id="deu_id" :disabled="disabled" :required="is_required"
      @md-selected="onChange">
      <md-option v-for="item in deuList" :key="item.id" :value="item.id">
        {{ item.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
export default {
  name: 'deus-dropdown',

  props: {
    label: { default: null, type: String },
    initial_value: { default: null, type: Number },
    is_required: { default: false, type: Boolean },
    region_id: { default: null, type: Number },
    disabled: { default: false, type: Boolean }
  },

  data() {
    return {
      deu_id: this.initial_value,
      deuListLoaded: false             // Добавляем флаг для отслеживания загрузки deuList
    };
  },

  methods: {
    onChange() {
      /*const find_deu_id = (!this.deu_id || this.deu_id == null) ? '' :
        this.deuList.find((el) => el.id == this.deu_id)
      const deu_desc = (!find_deu_id) ? '' : find_deu_id.description*/
      const deu_desc = !this.deu_id ? '' : this.deuList.find((el) => el.id == this.deu_id)?.description

      this.$emit('input', this.deu_id, deu_desc)
    }
  },

  watch: {
    region_id(newValue) {
      this.deuListLoaded = false
      this.deu_id = null
      const params = { region_id: newValue, show_all: !this.is_required, prefix: this.$t('road_network.dep') }
      this.$store.dispatch('GET_DEUS_DROPDOWN', params).then(() => {
        this.deuListLoaded = true     // Устанавливаем флаг, когда deuList загружен
        if (this.initial_value && !this.deu_id) this.deu_id = this.initial_value
      })
    },
    initial_value(newValue, oldValue) {
      if (newValue !== oldValue && this.deuListLoaded) {
        this.deu_id = newValue
      }
    }
  },

  computed: {
    deuList() {
      return this.$store.state.Dropdowns.deus;
    }
  }
}
</script>